var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5, 10, 20],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'no_spt')?_c('span',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.loadSpt(props)}}},[_vm._v(_vm._s(props.row.no_spt))])]):(props.column.field === 'status')?_c('span',[_c('span',{class:props.row.badge},[_vm._v(_vm._s(props.row.status))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title mb-0"},[_vm._v("Notifikasi")])])])
}]

export { render, staticRenderFns }