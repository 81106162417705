<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Notifikasi</h4>
            </div>
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5, 10, 20],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'no_spt'">
								<a href="javascript:void(0)" @click="loadSpt(props)">{{ props.row.no_spt }}</a>
							</span>
							<span v-else-if="props.column.field === 'status'">
								<span v-bind:class="props.row.badge">{{ props.row.status }}</span>
							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  import { getDataService} from '../../store/modules/crudservices'

  export default {
    name:'NotifikasiList',
    data () {
      return {
        columns:[ 
          {
            label: 'No. SPT',
            field: 'no_spt'
          }, {
            label: 'Daerah Tujuan',
            field: 'daerah_tujuan'
          }, {
            label: 'Tgl. Berangkat',
            field: 'tgl_berangkat'
          }, {
            label: 'Tgl. Kembali',
            field: 'tgl_kembali'
          }, {
            label: 'Status',
            field: 'status',
            width: '100px',
          }, {
            label: 'Keterangan',
            width: '140px',
            field: 'keterangan',
          }
        ],
        rows: [],
      }
    },
    methods: {
      refreshLists(){
        getDataService('/notif/grid').then(data => {
          this.rows = data.data
        })
      },
      loadSpt(props) {
        this.$router.push(`/spt/${props.row.id}/sppd`)
      },
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      this.refreshLists()
    }
  }
</script>